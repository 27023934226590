import CustomContextPad from './CustomContextPad';
import CustomPalette from './CustomPalette';
import CustomContextPadProvider from './CustomContextPadProvider'

export default {
  __init__: [ 'customContextPad', 'customPalette' ],
  customContextPad: [ 'type', CustomContextPad ],
  customPalette: [ 'type', CustomPalette ],
  // contextPadProvider: ['type', CustomContextPadProvider]
};
