var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "page-title",
        [
          _c(
            "template",
            { slot: "btn-inner" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "iconfont icon-fanhui" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.submit },
                  on: { click: _vm.saveModel }
                },
                [_vm._v(" 保存 ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c("div", { staticClass: "partition-area" }, [
        _c(
          "div",
          {
            staticClass: "edit-inner",
            style: { height: _vm.maxHeight + "px" }
          },
          [_c("workflow-editor", { ref: "editor" })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }