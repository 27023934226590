export var xmlStr = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://activiti.org/bpmn">
  <bpmn2:process id="Process_1" isExecutable="true">
    <bpmn2:startEvent id="StartEvent_1"/>
  </bpmn2:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn2:definitions>`
export const demoXml = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:activiti="http://activiti.org/bpmn" id="sample-diagram" targetNamespace="http://activiti.org/bpmn" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd">
  <bpmn2:process id="Process_1" name="测试" isExecutable="true">
    <bpmn2:startEvent id="StartEvent_1">
      <bpmn2:outgoing>Flow_1auzi1w</bpmn2:outgoing>
    </bpmn2:startEvent>
    <bpmn2:userTask id="Activity_0x53w3q" name="业务员" activiti:candidateGroups="ywactivitiTem">
      <bpmn2:incoming>Flow_1auzi1w</bpmn2:incoming>
      <bpmn2:outgoing>Flow_0t77h9d</bpmn2:outgoing>
    </bpmn2:userTask>
    <bpmn2:sequenceFlow id="Flow_1auzi1w" sourceRef="StartEvent_1" targetRef="Activity_0x53w3q" />
    <bpmn2:userTask id="Activity_0p5ti67" name="经理" activiti:candidateGroups="jinliactivitiTem">
      <bpmn2:incoming>Flow_0t77h9d</bpmn2:incoming>
      <bpmn2:outgoing>Flow_1x9xgi4</bpmn2:outgoing>
    </bpmn2:userTask>
    <bpmn2:sequenceFlow id="Flow_0t77h9d" sourceRef="Activity_0x53w3q" targetRef="Activity_0p5ti67" />
    <bpmn2:userTask id="Activity_15xqmnv" name="总裁" activiti:candidateGroups="zcactivitiTem">
      <bpmn2:incoming>Flow_1x9xgi4</bpmn2:incoming>
      <bpmn2:outgoing>Flow_1ykeist</bpmn2:outgoing>
    </bpmn2:userTask>
    <bpmn2:sequenceFlow id="Flow_1x9xgi4" sourceRef="Activity_0p5ti67" targetRef="Activity_15xqmnv" />
    <bpmn2:endEvent id="Event_10i5ovy">
      <bpmn2:incoming>Flow_1ykeist</bpmn2:incoming>
    </bpmn2:endEvent>
    <bpmn2:sequenceFlow id="Flow_1ykeist" sourceRef="Activity_15xqmnv" targetRef="Event_10i5ovy" />
  </bpmn2:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
      <bpmndi:BPMNEdge id="Flow_1auzi1w_di" bpmnElement="Flow_1auzi1w">
        <di:waypoint x="448" y="258" />
        <di:waypoint x="500" y="258" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0t77h9d_di" bpmnElement="Flow_0t77h9d">
        <di:waypoint x="600" y="258" />
        <di:waypoint x="660" y="258" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1x9xgi4_di" bpmnElement="Flow_1x9xgi4">
        <di:waypoint x="760" y="258" />
        <di:waypoint x="820" y="258" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1ykeist_di" bpmnElement="Flow_1ykeist">
        <di:waypoint x="920" y="258" />
        <di:waypoint x="982" y="258" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="412" y="240" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0x53w3q_di" bpmnElement="Activity_0x53w3q">
        <dc:Bounds x="500" y="218" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0p5ti67_di" bpmnElement="Activity_0p5ti67">
        <dc:Bounds x="660" y="218" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_15xqmnv_di" bpmnElement="Activity_15xqmnv">
        <dc:Bounds x="820" y="218" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_10i5ovy_di" bpmnElement="Event_10i5ovy">
        <dc:Bounds x="982" y="240" width="36" height="36" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn2:definitions>
`
