'use strict';
const store = require('../../../../../../../store/index')
var is = require('bpmn-js/lib/util/ModelUtil').is,
    entryFactory = require('../../../factory/EntryFactory');


module.exports = function(group, element, translate) {
  if (is(element, 'activiti:Assignable')) {
    // // 测试
    // group.entries.push(entryFactory.textField({
    //   id : 'test',
    //   label : '测试',
    //   modelProperty : 'test'
    // }));

    // Assignee
    // group.entries.push(entryFactory.textField({
    //   id : 'assignee',
    //   label : translate('Assignee'),
    //   modelProperty : 'assignee'
    // }));

    // Candidate Users
    group.entries.push(entryFactory.textField({
      id : 'candidateUsers',
      label : translate('Candidate Users'),
      modelProperty : 'candidateUsers'
    }));
    const ruleList = [ {name: '', value: ''}]
    store.default.state.orgRoleList.forEach(rule => {
      ruleList.push(rule)
    })
    // Candidate Groups
    group.entries.push(entryFactory.selectBox({
      id : 'candidateGroups',
      label : translate('Candidate Groups'),
      modelProperty : 'candidateGroups',
      selectOptions: ruleList
    }));

    // Due Date
    // group.entries.push(entryFactory.textField({
    //   id : 'dueDate',
    //   description : translate('The due date as an EL expression (e.g. ${someDate} or an ISO date (e.g. 2015-06-26T09:54:00)'),
    //   label : translate('Due Date'),
    //   modelProperty : 'dueDate'
    // }));

    // FollowUp Date
    // group.entries.push(entryFactory.textField({
    //   id : 'followUpDate',
    //   description : translate('The follow up date as an EL expression (e.g. ${someDate} or an ' +
    //                 'ISO date (e.g. 2015-06-26T09:54:00)'),
    //   label : translate('Follow Up Date'),
    //   modelProperty : 'followUpDate'
    // }));

    // priority
    // group.entries.push(entryFactory.textField({
    //   id : 'priority',
    //   label : translate('Priority'),
    //   modelProperty : 'priority'
    // }));
  }
};
