<!-- edit -->
<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button icon="iconfont icon-fanhui" @click="$router.back()">
          返回
        </el-button>
        <el-button type="primary" :loading="loading.submit" @click="saveModel">
          保存
        </el-button>
      </template>
    </page-title>
    <div class="partition-area">
      <div class="edit-inner" :style="{height: maxHeight + 'px'}">
        <workflow-editor ref="editor" />
      </div>
    </div>
  </el-main>
</template>
<script>
import WorkflowEditor from '@/components/template/workflow-editor'
// eslint-disable-next-line no-unused-vars
import { xmlStr, demoXml } from '@/mock/data/xmlStr'
import { autoHeightMixin } from '@/assets/js/mixins'
export default {
  components: { WorkflowEditor },
  mixins: [autoHeightMixin],
  data () {
    return {
      dataId: this.$route.query.id,
      xmlStr: '',
      loading: {
        detail: false,
        submit: false
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.workflow.details(this.dataId).then(result => {
        this.xmlStr = result.data.data.bpmn
        this.$refs.editor.init(result.data.data.bpmn)
      }).finally(() => {
        this.loading.detail = false
      })
    },
    getXml () {
      this.$refs.editor.getXml().then(result => {
      })
    },
    saveModel () {
      this.loading.submit = true
      this.$refs.editor.getXml().then(result => {
        console.log(result)
        const data = {
          modelId: this.dataId,
          bpmnTxt: result
        }
        return this.api.workflow.save(data)
      }).then(result => {
        this.$message.success(result.data.message || '保存成功')
      }).finally(() => {
        this.loading.submit = false
      })
    }
  }
}
</script>
<style lang="less">
.edit-inner {
  width: 100%;
  height: 500px;
}
</style>
