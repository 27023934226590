'use strict';
const store = require('../../../../../../../store/index')
const _ = require('lodash')
var entryFactory = require('../../../factory/EntryFactory'),
	getBusinessObject = require('bpmn-js/lib/util/ModelUtil').getBusinessObject,
	utils = require('../../../Utils'),
	cmdHelper = require('../../../helper/CmdHelper');

module.exports = function(group, element, translate, options) {

	var description = options && options.description;
	const orgSelect = [{ name: '', value: '' }]
	_.filter(store.default.state.orgList).forEach(rule => {
		orgSelect.push(rule);
	})
	// Id
	group.entries.push(entryFactory.selectBox({
		id: 'org',
		label: translate('Org'),
		description: description && translate(description),
		modelProperty: 'org',
		selectOptions: orgSelect,
		getProperty: function(element) {
			return getBusinessObject(element).org;
		},
		setProperty: function(element, properties) {
			element = element.labelTarget || element;

			return cmdHelper.updateProperties(element, properties);
		},
		validate: function(element, values) {
			var orgValue = values.org;
			if (orgValue) {
				store.default.commit('updateOrgSelectOptions', orgValue)
			}
			var orgError = '';
			return orgError ? { org: orgError } : {};
		}
	}));

};
