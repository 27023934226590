var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "workflow-edit" }, [
    _c("div", { ref: "bpmnEditor", staticClass: "bpmn-editor" }),
    _c("div", {
      staticClass: "bpmn-edit-properties-panel",
      attrs: { id: "bpmn_properties_panel" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }