'use strict';
const store = require('../../../../../../../store/index')
const _ = require('lodash')
var entryFactory = require('../../../factory/EntryFactory'),
    getBusinessObject = require('bpmn-js/lib/util/ModelUtil').getBusinessObject,
    utils = require('../../../Utils'),
    cmdHelper = require('../../../helper/CmdHelper');

module.exports = function(group, element, translate, options) {

  var description = options && options.description;
  const idSelect = [{ name: '', value: '' }]
  _.filter(store.default.state.workflowRoutineDict, { type: 'flowKey'}).forEach(rule => {
    idSelect.push(rule)
  })
  // Id
  group.entries.push(entryFactory.selectBox({
    id: 'id',
    label: translate('Id'),
    description: description && translate(description),
    modelProperty: 'id',
    selectOptions: idSelect,
    getProperty: function(element) {
      return getBusinessObject(element).id;
    },
    setProperty: function(element, properties) {
      element = element.labelTarget || element;

      return cmdHelper.updateProperties(element, properties);
    },
    validate: function(element, values) {
      var idValue = values.id;

      var bo = getBusinessObject(element);

      var idError = utils.isIdValid(bo, idValue, translate);

      return idError ? { id: idError } : {};
    }
  }));

};
