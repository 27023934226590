<template>
  <div class="workflow-edit">
    <div ref="bpmnEditor" class="bpmn-editor" />
    <div id="bpmn_properties_panel" class="bpmn-edit-properties-panel" />
  </div>
</template>

<script>
import BpmnModeler from 'bpmn-js/lib/Modeler'
import orgProps from './properties-panel/provider/bpmn/parts/OrgProps'
// 工具栏相关
// import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda'
import propertiesProviderModule from './properties-panel/provider/activiti'
import propertiesPanelModule from './properties-panel'
// import propertiesPanelModule from 'bpmn-js-properties-panel'
import activitiModdleDescriptor from './activiti.json'
//import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda'
import customControlsModule from './customControls'
import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css'
import customTranslate from './customTranslate/customTranslate'
import {cookie} from '../../../assets/js/config'
export default {
  name: 'WorkflowEditor',
  props: {
    orgRoleList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      bpmnModeler: null
    }
  },
  created () {
    this.getFirmList()
    this.getDict()
  },
  methods: {
    init (xml) {
      const bpmnEditor = this.$refs.bpmnEditor
      const customTranslateModule = {
        translate: ['value', customTranslate]
      }
      this.bpmnModeler = new BpmnModeler({
        container: bpmnEditor,
        propertiesPanel: {
          parent: '#bpmn_properties_panel'
        },
        additionalModules: [propertiesProviderModule, propertiesPanelModule, customControlsModule, customTranslateModule],
        moddleExtensions: {
          // camunda: camundaModdleDescriptor,
          activiti: activitiModdleDescriptor
        }
      })
      this.bpmnModeler.importXML(xml).then(result => {
        console.log(result, '加载成功')
      }).catch(e => {
        console.error(e)
      })
    },
    getXml () {
      return new Promise((resolve, reject) => {
        this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
          resolve(xml)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getFirmList () {
      if (this.$store.state.orgRoleList.length <= 0) {
        this.api.workflow.getFirmList().then(result => {
          const list = []
          result.data.data.forEach(item => {
            list.push({
              name: item.roleName,
              value: item.roleCode
            })
          })
          this.$store.commit('updateOrgRoleList', list)
        })
      }
    },
    getDict () {
      this.getRoutine()
      this.getExtend()
      this.getRoot()
    },
    getRoutine () {
      if (this.$store.state.workflowRoutineDict.length <= 0) {
        const data = {
          type: 'flowKey'
        }
        this.api.workflow.getDictList(data).then(result => {
          const list = []
          result.data.data.forEach(item => {
            list.push({
              type: item.type,
              name: item.label,
              value: item.code,
            })
          })
          this.$store.commit('updateWorkflowRoutineDict', list)
        })
      }
    },
    getExtend () {
      this.api.workflow.getDictListExt().then(result => {
        this.$store.commit('updateWorkflowExtendDict', result.data.data)
      })
    },
    getRoot () {
      if (this.$store.state.workflowExtendName.length <= 0) {
        const data = {
          type: 'root'
        }
        this.api.workflow.getDictList(data).then(result => {
          const rootList = []
          result.data.data.forEach(item => {
            rootList.push({
              name: item.remark,
              value: item.code
            })
          })
          this.$store.commit('updateWorkflowExtendName', rootList)
        })
      }
    }
  }
}
</script>

<style lang="less">
.workflow-edit {
  width: 100%;
  height: 100%;
  position: relative;
}
.bpmn-editor {
  width: 100%;
  height: 100%;
}
.bpmn-edit-properties-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  overflow: auto;
  .bpp-properties-panel [type=text], .bpp-properties-panel [contenteditable], .bpp-properties-panel textarea, .bpp-properties-panel select {
    box-sizing: border-box;
  }
  .bpp-properties-panel {
    height: 100%;
  }
}
</style>
